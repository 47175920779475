var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-center align-center",style:(`padding:${_vm.buttonPadding};
  background-color: ${_vm.disable ? '#D8D9DC' : _vm.backgroundColor};
  border-color:${_vm.disable ? '#00000000' : _vm.borderColor};
  border-radius:${_vm.borderRadius};
  border: ${_vm.borderOutLine}`),on:{"click":_vm.onClick}},[(_vm.buttonTitle)?_c('span',{class:_vm.textColorCode
        ? ''
        : _vm.buttonTitleTextClass
        ? `${_vm.buttonTitleTextClass} ${_vm.textColor}--text`
        : `font-g12-13 ${_vm.disable ? 'white' : _vm.textColor}--text`,style:(_vm.textColorCode ? `color: ${_vm.textColorCode} ` : '')},[_vm._v(_vm._s(_vm.buttonTitle))]):_vm._e(),_vm._t("content")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }