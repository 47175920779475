
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { AppProfile } from '../../../../../types/App'

export default Vue.extend<Data, Methods, Computed>({
  computed: {
    ...mapGetters('ProfileStore', ['getProfile']),
    ...mapState('DeviceStore', ['deviceInfo']),
  },
  methods: {
    askToKakao() {
      this.$tracking('checkin_contact')

      const url = this.getProfile.urls.chatbot
      const payload = {
        url,
        isGoneBottomView: true,
      }
      if (this.deviceInfo == null) {
        window.open(url, '_blank')
        return
      }
      this.$openSubWebView(payload)
    },
  },
})

interface Computed {
  getProfile: AppProfile
  deviceInfo: DeviceInfo | null
}
interface Data {}
interface Methods {
  askToKakao: () => void
}
