
import Vue from 'vue'
import { checkInFAQ } from '@/helper/checkin'

export default Vue.extend<Data, Methods, Computed>({
  data() {
    return {
      panel: null,
      FAQList: checkInFAQ,
    }
  },
  methods: {
    toggleFAQ(index) {
      this.$tracking(`checkin_notice_0${index + 1}`)
    },
  },
})

interface Computed {}
interface Data {
  panel: any
}
interface Methods {
  toggleFAQ: (index: number) => void
}
