
import Vue from 'vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    bottomBannerList: {
      type: Array,
      default: () => [{ icon: '', link: '' }],
    },
  },
})

interface Props {
  bottomBannerList: Array<{
    icon: string
    link: string
  }>
}
interface Computed {}
interface Data {}
interface Methods {}
