
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend<Data, Method, Computed, Props>({
  name: 'CheckInPrevHistoryDialog',
  props: {
    showPrevDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedEventArrayIndex: 0,
    }
  },
  computed: {
    ...mapGetters('CheckInStore', ['getCheckInPrevData']),

    getCalender() {
      const selectedEvent =
        this.getCheckInPrevData[this.selectedEventArrayIndex]

      const week = ['일', '월', '화', '수', '목', '금', '토']
      const year = parseInt(selectedEvent.year)
      const month = parseInt(selectedEvent.month) - 1
      const days = []

      const firstDay: number = new Date(year, month, 1).getDay()
      const lastDate: number = new Date(year, month + 1, 0).getDate()

      const startDate = new Date(selectedEvent.startDate).getDate()
      const endDate = new Date(selectedEvent.endDate).getDate()

      for (let i = 0; i < firstDay; i++) {
        days.push({
          day: 0,
        })
      }
      for (let i = 1; i <= lastDate; i++) {
        days.push({
          day: i,
          checked:
            startDate <= i &&
            i <= endDate &&
            selectedEvent.previousCheckInDateList[i - startDate].isCheckIn,
          stamped:
            startDate <= i &&
            i <= endDate &&
            selectedEvent.previousCheckInDateList[i - startDate].isApply,
        })
      }

      const weekCount = Math.ceil(days.length / week.length)
      const currentTotalCount = selectedEvent.totalCount

      return {
        days,
        week,
        weekCount,
        month,
        year,
        currentTotalCount,
        hasNext: selectedEvent.hasNext,
        hasPrev: selectedEvent.hasPrev,
      }
    },
    parsedMonth() {
      const currentMonth = this.getCalender.month + 1
      if (currentMonth >= 10) {
        return String(currentMonth)
      } else {
        return '0' + currentMonth
      }
    },
  },
  created() {
    this.selectedEventArrayIndex = this.getCheckInPrevData.length - 1
  },
  methods: {
    prevMonth() {
      if (!this.getCheckInPrevData[this.selectedEventArrayIndex - 1]) return
      this.selectedEventArrayIndex = this.selectedEventArrayIndex - 1
    },
    nextMonth() {
      if (!this.getCheckInPrevData[this.selectedEventArrayIndex + 1]) return
      this.selectedEventArrayIndex = this.selectedEventArrayIndex + 1
    },
  },
})

interface Props {
  showPrevDialog: boolean
}
interface Data {
  selectedEventArrayIndex: number
}
interface Method {
  prevMonth: () => void
  nextMonth: () => void
}
interface Computed {
  getCalender: {
    days: Array<{
      day: number
      checked?: boolean
      stampled?: boolean
    }>
    week: Array<string>
    weekCount: number
    month: number
    year: number
    currentTotalCount: number | undefined
    hasNext: boolean
    hasPrev: boolean
  }
  getCheckInPrevData: Array<CheckInPrevDataGroup>
  parsedMonth: string
}
