export const checkInFAQ = [
  {
    title: 'Q. 체크인 참여 조건은 어떻게 되나요? ',
    content: '셀룩 회원만 참여 가능하며, ID 당 1일 1회 참여할 수 있습니다.',
  },
  {
    title: 'Q. 경품을 중복으로 받을 수 있나요?',
    content: ` - 체크인 횟수 조건을 만족하는 회원 중 추첨을 통해 지급되며, 중복으로 지급되지 않습니다. 
    - 추첨 결과에 따라 본인이 만족하는 조건보다 낮은 단계의 경품이 지급될 수 있습니다. (Ex. 전일 출석자더라도 3,000 Point 또는 1,000 Point 경품에 당첨될 수 있음)`,
  },
  {
    title: 'Q. 리워드는 언제 지급되나요?',
    content:
      '당월에 해당하는 리워드는 차월 영업일 기준 10일 이내에 지급됩니다.',
  },
]
