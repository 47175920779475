
import Vue from 'vue'
import Button from '@/components/common/buttons/Button.vue'
import Lottie from '@/components/common/lottie/LottieView.vue'
import { AnimationItem } from 'lottie-web'
import { destroyLottieAnim } from '@/plugins/util/lottie'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Button, Lottie },
  props: {
    showConfirmDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      anim: null,
    }
  },
  beforeDestroy() {
    destroyLottieAnim(this.anim)
  },
  methods: {
    handleAnim(anim: AnimationItem | null) {
      this.anim = anim
    },
  },
})

interface Props {
  showConfirmDialog: boolean
}

interface Computed {}
interface Data {
  anim: AnimationItem | null
}
interface Methods {
  handleAnim(anim: AnimationItem | null): void
}
