
import Vue from 'vue'

//이미 만들어져 있던 Button.vue 가 피그마 컴포넌트의 디자인 요구사항과 달라져 만든 버튼 컴포넌트 입니다.

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CellookButton',
  props: {
    buttonTitle: {
      type: String,
      default: undefined,
    },
    buttonTitleTextClass: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '#FFFFFF',
    },
    borderColor: {
      type: String,
      default: '#003399',
    },
    borderRadius: {
      type: String,
      default: '4px',
    },
    textColor: {
      type: String,
      default: 'blue',
    },
    buttonPadding: {
      type: String,
      default: '16px',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    borderOutLine: {
      type: String,
      default: '',
    },
    textColorCode: {
      type: String,
      default: '',
    },
  },
  computed: {},
  methods: {
    onClick() {
      if (this.disable) {
        return
      }

      this.$emit('click')
    },
  },
})

interface Data {}
interface Methods {
  onClick(): void
}
interface Computed {}

interface Props {
  buttonTitle: string
  borderColor: string
  backgroundColor: string
  borderRadius: string
  textColor: string
  buttonTitleTextClass: string
  buttonPadding: string
  disable: boolean
  borderOutLine: string
  textColorCode: string
}
