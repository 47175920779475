
import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import CellookButton from '@/components/common/buttons/CellookButton.vue'
import CheckInConfirmDialog from './checkin/CheckInConfirmDialog.vue'
import CheckInAskKakao from './checkin/CheckInAskKakao.vue'
import CheckInFaqPanel from './checkin/CheckInFaqPanel.vue'
import { PostCheckIn } from '@/api/displayAPI/EventAPI'
import CheckInBottomBanners from './checkin/CheckInBottomBanners.vue'
import CheckInPrevHistoryDialog from './checkin/CheckInPrevHistoryDialog.vue'
// import checkin from '@/assets/event/'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    CellookButton,
    CheckInConfirmDialog,
    CheckInAskKakao,
    CheckInFaqPanel,
    CheckInBottomBanners,
    CheckInPrevHistoryDialog,
  },
  props: {
    isFetched: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showConfirmDialog: false,
      showPrevDialog: false,

      // DB에서 주는 날짜값들은 첫째날부터 주지 않는다... ex) 3.10 ~ 3~31
      eventStartDate: 0,
      eventStartYear: 0,
      eventStartMonth: 0,
      makeBtnDisabled: false,
      videoHeight: 0,
    }
  },
  mounted() {
    if (this.getCheckInStoreScrollLocation) {
      this.$getAppHtml.scrollTo({ top: this.getCheckInStoreScrollLocation })
      this.fetchCheckInStoreScrollLocation(0)
    }
  },

  created() {
    const eventStartDay = new Date(this.getCheckInData.checkInDateList[0].date)

    this.eventStartDate = eventStartDay.getDate()
    this.eventStartYear = eventStartDay.getFullYear()
    this.eventStartMonth = eventStartDay.getMonth() + 1
  },

  watch: {
    isFetched(curVal: boolean, oldVal: boolean) {
      if (!oldVal && curVal) {
        this.makeBtnDisabled = false
      }
    },
  },
  computed: {
    ...mapState('BannerStore', ['onBanner']),
    ...mapState('UserStore', ['user']),
    ...mapState('DeviceStore', ['deviceInfo']),
    ...mapGetters('CheckInStore', [
      'getCheckInData',
      'getFromNoWhere',
      'getCheckInStoreScrollLocation',
      'getCheckInPrevPage',
    ]),
    ...mapGetters('RouteHistoryStore', ['getPreviousPage']),

    getCalender() {
      const today: Date = new Date(this.getCheckInData.today)
      const days: number[] = []
      const week: Array<string> = ['일', '월', '화', '수', '목', '금', '토']
      const year: number = today.getFullYear()
      const month: number = today.getMonth()
      const day: number = today.getDate()

      const firstDay: number = new Date(year, month, 1).getDay()
      const lastDate: number = new Date(year, month + 1, 0).getDate()

      for (let i = 0; i < firstDay; i++) {
        days.push(0)
      }
      for (let i = 1; i <= lastDate; i++) {
        days.push(i)
      }

      const weekCount = Math.ceil(days.length / week.length)

      return {
        day,
        days,
        week,
        weekCount,
        month,
        year,
      }
    },
    headerAnimClass(): string {
      if (this.onBanner) {
        return `common-header-visible`
      } else {
        return 'common-header-animate'
      }
    },
    isTodayCheckin(): boolean | undefined {
      if (
        this.eventStartYear !== this.getCalender.year ||
        this.eventStartMonth !== this.getCalender.month + 1
      ) {
        return
      }

      const today = this.getCheckInData.checkInDateList.find(
        (item) => new Date(item.date).getDate() === this.getCalender.day
      )

      return today?.isCheckIn
    },
    checkInbtnText(): string {
      let result = ''
      if (this.isTodayCheckin && this.getCheckInData.isApplyActive) {
        result = '응모 완료'
      } else if (this.isTodayCheckin) {
        result = '오늘 체크인 완료'
      } else {
        result = '체크인 하기'
      }

      return result
    },
    daysChecked() {
      return (date: number): string | undefined => {
        if (this.calendarCheck(date)) return

        if (
          this.getCheckInData.checkInDateList[date - this.eventStartDate] &&
          this.getCheckInData.checkInDateList[date - this.eventStartDate]
            .isCheckIn
        ) {
          if (
            this.getCheckInData.checkInDateList[date - this.eventStartDate]
              .isApply
          ) {
            return 'is-stamped checked-icon'
          } else {
            return 'gray100 gray900--text checked-icon'
          }
        }
      }
    },
    getPrize() {
      return (date: number): string | undefined => {
        if (this.calendarCheck(date)) return

        if (
          this.getCheckInData.checkInDateList[date - this.eventStartDate] &&
          this.getCheckInData.checkInDateList[date - this.eventStartDate]
            .isApply
        ) {
          return this.getCheckInData.checkInDateList[date - this.eventStartDate]
            .prize
        }
      }
    },
    isToday() {
      return (date: number): string | undefined => {
        if (date === this.getCalender.day) {
          return 'is-today'
        }
      }
    },
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    ...mapActions('HomeStore', ['fetchMainTab']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('CheckInStore', [
      'fetchNoticeNo',
      'fetchCheckInStoreScrollLocation',
      'fetchCheckInPrevPage',
      'fetchCheckInPrevData',
    ]),
    /*
     * ios17 에서 비디오 터치(스크롤) 동작하지 않아 만든 궁여지책
     * */
    onVideoLoaded() {
      const videoElement = this.$refs.video as HTMLVideoElement
      this.videoHeight = videoElement?.clientHeight ?? 0
    },
    async onCheckIn() {
      if (!this.isLogined) {
        this.fetchLoginDialog({ yn: true })
      } else {
        try {
          this.fetchLoading({ yn: true })
          await PostCheckIn({ checkInNo: String(this.$route.query.no) })
          this.makeBtnDisabled = true
          this.$emit('onCheckIn')
          this.showConfirmDialog = true
          this.$tracking('checkin_check')
        } catch (e) {
          this.$emit('onCheckIn')
        } finally {
          this.fetchLoading({ yn: false })
        }
      }
    },

    async backSpace() {
      this.$tracking('mypage_checkin_back')

      if (this.getFromNoWhere) {
        this.$router.push('/')
      } else if (this.getCheckInPrevPage.name) {
        this.$router.push(this.getCheckInPrevPage.fullPath)
        this.fetchCheckInPrevPage({
          fullPath: '/',
          name: null,
          path: '/',
        })
      } else {
        this.$router.back()
      }
    },
    goToEventOrExhibition(index: number, link: string) {
      this.$tracking(`checkin_banner_0${index + 1}`)
      const ourUrl = process.env.VUE_APP_CELLOOK_URL
      if (link === ourUrl) {
        this.$router.push('/')
      } else if (link.startsWith(ourUrl)) {
        this.fetchCheckInStoreScrollLocation(this.$getAppHtml.scrollTop)
        const routePath = link.split('/')
        this.$router.push({
          path: routePath[routePath.length - 1],
        })
      } else {
        window.location.href = link
      }
    },

    calendarCheck(date: number): boolean | undefined {
      if (!this.isLogined) return true

      // 년도나 월이 다르면 리턴
      if (
        this.eventStartYear !== this.getCalender.year ||
        this.eventStartMonth !== this.getCalender.month + 1
      ) {
        return true
      }

      // 일자가 이벤트 시작일 보다 적으면 리턴
      if (date < this.eventStartDate) return true
    },
    async goToMyPageBoard(noticeDetailUrl: string) {
      if (!this.deviceInfo) {
        // 앱이 아닐시는 iframe 이동
        this.fetchCheckInStoreScrollLocation(this.$getAppHtml.scrollTop)

        if (this.getPreviousPage.name) {
          if (this.getPreviousPage.name !== 'MyPageBoardBoard') {
            this.fetchCheckInPrevPage(this.getPreviousPage)
          }
        } else {
          this.fetchCheckInPrevPage({
            fullPath: '/',
            name: 'Main',
            path: '/',
          })
        }

        await this.fetchNoticeNo({ no: noticeDetailUrl })
        this.$router.push('mypageBoard')
      } else {
        // 앱 일 경우 서브웹뷰
        let url = ''
        if (process.env.VUE_APP_ENV === 'production') {
          url = `https://intro.cellook.kr/${noticeDetailUrl}`
        } else {
          url = `https://intro-alpha.cellook.kr/${noticeDetailUrl}`
        }
        const payload = {
          url: url + '?referrer=cellook',
          title: '공지사항',
        }
        this.$rendingOutsidePage(this.$device, payload)
      }
    },
    async openPrevCheckinHistory() {
      if (!this.isLogined) {
        this.fetchLoginDialog({ yn: true })
      } else {
        await this.fetchCheckInPrevData()
        this.showPrevDialog = true
      }
    },
  },
})

interface Props {
  isFetched: boolean
}
interface Data {
  showConfirmDialog: boolean
  eventStartDate: number
  eventStartYear: number
  eventStartMonth: number
  makeBtnDisabled: boolean
  showPrevDialog: boolean
  videoHeight: number
}
interface Computed {
  onBanner: boolean
  getCalender: GetCalender
  getFromNoWhere: boolean
  headerAnimClass: string
  user: UserProfileGroup
  deviceInfo: DeviceInfo | null
  getCheckInData: CheckInDataGroup
  checkInbtnText: string
  isTodayCheckin: boolean | undefined
  daysChecked: any
  isToday: any
  getPrize: any
  getPreviousPage: {
    fullPath: string
    name: string | null
    path: string
  }
  getCheckInStoreScrollLocation: number
  getCheckInPrevPage: {
    fullPath: string
    name: string | null
    path: string
  }
}
interface Methods {
  onCheckIn: () => void
  fetchLoginDialog: (payload: { yn: boolean }) => void
  backSpace: () => void
  goToEventOrExhibition: (index: number, link: string) => void
  calendarCheck(date: number): boolean | undefined
  fetchMainTab: (payload: { tab: number }) => Promise<void>
  fetchLoading(payload: { yn: boolean }): Promise<void>
  goToMyPageBoard: (noticeDetailUrl: string) => void
  fetchNoticeNo: (payload: { no: string }) => Promise<void>
  fetchCheckInStoreScrollLocation(scroll: number): void
  fetchCheckInPrevPage: (payload: {
    fullPath: string
    name: string | null
    path: string
  }) => Promise<void>
  openPrevCheckinHistory: () => void
  fetchCheckInPrevData: () => Promise<void>
  onVideoLoaded(): void
}
