
import Vue from 'vue'
import { mapActions } from 'vuex'
import CheckIn from '@/components/views/event/CheckIn.vue'

export default Vue.extend<Data, Methods, Computed>({
  components: { CheckIn },
  watch: {
    isLogined() {
      this.init()
    },
  },
  data() {
    return {
      loadingStatus: false,
      isFetched: false,
    }
  },
  created() {
    this.init()
    this.fetchOnBanner({ onBanner: true })
  },
  methods: {
    ...mapActions('CheckInStore', ['fetchCheckInData']),
    ...mapActions('BannerStore', ['fetchOnBanner']),

    async init() {
      try {
        this.isFetched = false
        await this.fetchCheckInData({
          checkInNo: String(this.$route.query.no),
        })
        this.loadingStatus = true
        this.isFetched = true
      } catch (e) {
        console.log(e)
        this.$router.push('/')
      }
    },
  },
})

interface Data {
  loadingStatus: boolean
  isFetched: boolean
}
interface Computed {}
interface Methods {
  fetchCheckInData(payload: { checkInNo: string }): Promise<void>
  init(): void
  fetchOnBanner: ({ onBanner }: { onBanner: boolean }) => void
}
